<template>
  <div class="toolBarView">
    <div class="defaultTool">
      <input
        :value="value"
        ref="inputChat"
        @input="handleInput"
        class="defaultTool_input"
        @keydown.enter="handlerSend"
        @focus="handleFocus"
        @blur="handleBlur" />
      <!--      <img src="/images/chatView/face.png" class="icon" @click="handlerToolClick('face')"/>-->
      <img src="/images/chatView/more.png" class="icon" @click="isShowMoreTool = !isShowMoreTool" />
      <van-button :disabled="!(value.length > 0)" @click="handlerSend">发送</van-button>
    </div>
    <div class="moreTool" v-if="isShowMoreTool">
      <img v-for="item in moreToolOptions" :key="item.key" :src="item.iconUrl" @click="handlerToolClick(item.key)"
        class="moreToolIcon" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    const initImgUrl = (iconName) => {
      return `/images/chatView/${iconName}`
    }
    return {
      isShowMoreTool: false,
      moreToolOptions: [
        {key: 'img', iconUrl: initImgUrl('img.png')},
        {key: 'camera', iconUrl: initImgUrl('camera.png')},
      ],
      inputFocus: false
    }
  },

  methods: {
    /**
     * 输入事件
     */
    handleInput (e) {
      if (e.target.value.length > 100) {
        e.target.value = e.target.value.substring(0, 100)
      }
      let {value} = e.target || {}
      this.$emit('input', value)
    },
    handleFocus () {
      this.isShowMoreTool = false
      this.$emit('focus')
    },
    handleBlur () {
      this.inputFocus = false
    },
    /**
     * 工具点击事件
     */
    handlerToolClick (key) {
      // console.log(key)
      this.$emit('handlerToolClick', key)
    },
    /**
     * 发送消息
     */
    handlerSend () {
      this.$refs.inputChat.focus()
      // console.log('ss----d', this.value)
      this.$emit('send', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.toolBarWrap {
  background-color: #fff;
  padding-bottom: 300px;
}

.toolBarView {
  .icon {
    height: 22px;
    width: 22px;
    margin: 0 10px;
  }

  .defaultTool {
    height: 50px;
    background-color: white;
    box-sizing: border-box;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_input {
      background-color: $whiteGray;
      border: none;
      outline: none;
      border-radius: 4px;
      height: 36px;
      padding: 0 18px;
      flex: 1;
      width: 0;
      font-size: 14px;
    }

    .van-button {
      height: 28px;
      background-color: $mainColor;
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
      border: none;
    }
  }

  .moreTool {
    border-top: 1px solid #CCCCCC;
    display: flex;
    height: 120px;
    background-color: white;
    padding: 0 20px;
    margin: 0 -10px;

    .moreToolIcon {
      width: 44px;
      height: 44px;
      margin: 10px;
    }
  }
}
</style>
