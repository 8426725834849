import request from "@/utils/request";

export function privateletterList(params) {
    return request({
        url: '/api/zeekr-article-new/privateLetter/my-private-letter-list',
        method: 'get',
        params
    })
}

export function privateletterFriendList(params) {
    return request({
        url: '/api/zeekr-article-new/privateLetter/my-private-letter',
        method: 'get',
        params
    })
}

// /zeekr-article-new/privateletter/save

export function privateletterSave(data) {
    return request({
        url: '/api/zeekr-article-new/privateLetter/save',
        method: 'post',
        data
    })
}

/**
 * 全部已读
 * @returns {AxiosPromise}
 */
export function privateletterAllRead() {
    return request({
        url: '/api/zeekr-article-new/privateLetter/all-read',
        method: 'get'
    })
}
