<template>
  <div class="chatView">
    <pageHead :title="friend.userName" />
    <div class="chatView_content" id="chartView" ref="chatViewContent" @click="handleContentClick">
      <messageItem
        :id="'item-'+index"
        v-for="(item,index) in records"
        :key="item.id"
        :item="item"
        :value="item.msg || item.image"
        :is-mine="item.isSendMe"
        :messageType='handleMsgType(item)'
        :userIcon='item.userAvatar'
        :articleId='item.articleId'
        :name='item.name'
        @imgClick='handleImgClick(item)'
        @load="imageLoad"
        @click="handleMsgClick(item)"
        class="chatView_content_item" :time='item.createTime' :superAdmin="item.userSuperAdmin" />
    </div>
    <toolbar v-model="inputValue" @send="send" @handlerToolClick='handlerToolClick' @input="handleInput"
      @focus="handleFocus" />
    <van-image-preview v-model="showImgPreview" :images="imgList" :start-position='startPosition' />
  </div>
</template>

<script>
import Exif from 'exif-js'
import pageHead from '@/components/pageHead'
import messageItem from "./components/messageItem";
import toolbar from "./components/toolbar";
import {privateletterFriendList, privateletterSave} from "@/api/privateletter";
import {selectFile} from "@/utils/selectImg";
import {FLUSH_TIME_SECONDS} from "@/config/config";
import {mapGetters} from "vuex";

export default {
  components: {
    pageHead,
    messageItem,
    toolbar
  },
  data () {
    return {
      friend: {},
      records: [],
      inputValue: '',
      params: {
        pageSize: 50,
        pageNum: 1,
        friendId: this.$route.query.userId,
        hasNextPage: true,
      },
      showImgPreview: false,
      startPosition: 0,
      inputFocus: false,
    }
  },
  computed: {
    imgList () {
      return this.records.filter(item => {
        return item.msgType == 2
      }).map(item => {
        return item.image
      })
    },
    ...mapGetters([
      'isAndroid'
    ])
  },
  created () {
    this.friend = this.$route.query;
    this.loadList();
    this.timer();
    // 查询聊天记录
  },
  beforeDestroy () {
    clearInterval(this.timeoutTimer)
  },
  methods: {
    // 发送消息
    send (msg) {
      if (msg.trim()) {
        privateletterSave({
          msg,
          toId: this.friend.userId,
          toName: this.friend.userName
        }).then(() => {
          this.loadList(true)
          this.inputValue = ""
        })
      }
      this.inputFocus = false
    },
    sendImage (image) {
      privateletterSave({
        image,
        msgType: 2,
        toId: this.friend.userId,
        toName: this.friend.userName
      }).then(() => {
        this.loadList(true)
      })
    },
    loadList (foluceFlush = false) {
      // this.params
      if (foluceFlush) {
        let params = JSON.parse(JSON.stringify(this.params))
        params.pageNum = 1;
        privateletterFriendList(params).then(res => {
          this.handleData(res)
        })
      } else {
        if (this.params.hasNextPage) {
          privateletterFriendList(this.params).then(res => {
            this.handleData(res)
          })
        }
      }
    },
    handleData (res) {
      this.params.hasNextPage = res.current < res.pages;
      let orginLen = this.records.length;
      let oldRecords = this.records;

      res.records.forEach(item => {
        let count = oldRecords.filter(innerItm => innerItm.id === item.id)
        if (count.length === 0) {
          oldRecords.push(item)
        }
        // 判断是否是我的消息
        item.isSendMe = this.isMe(item.userId)
        const reg = /({{id:)[0-9]+,name:.{0,10}(}})$/g
        if (item.msg.match(reg)) {
          const msg = item.msg
          item.msg = msg.substring(0, msg.lastIndexOf(msg.match(reg)))
          const infor = msg.substring(msg.lastIndexOf(msg.match(reg)))
          item.articleId = infor.substring(infor.indexOf(':') + 1, infor.indexOf(','))
          item.name = infor.substring(infor.lastIndexOf(':') + 1, infor.lastIndexOf('}}'))
        }
      })

      oldRecords.sort((a, b) => {
        // console.log(a, b);
        let aTim = ''
        let bTim = ''
        if (a.createTime.replaceAll) {
          aTim = new Date(a.createTime.replaceAll('-', '/')).getTime();
          bTim = new Date(b.createTime.replaceAll('-', '/')).getTime();
        } else {
          aTim = new Date(a.createTime).getTime();
          bTim = new Date(b.createTime).getTime();
        }
        // console.log({aTim,bTim})
        return aTim - bTim;
      })
      this.oldRecords = oldRecords;
      if (oldRecords.length - orginLen > 0) {
        this.goToEnd();
      }
    },

    isMe (sendUserId) {
      let userId = this.$store.getters.user.user_id;
      return parseInt(userId) === parseInt(sendUserId)
    },
    goToEnd () {
      setTimeout(() => {
        let scroll = document.getElementById("chartView");
        scroll.scrollTo(0, 1000000)
      }, 300)
    },

    timer () {
      if (this.timeoutTimer) clearTimeout(this.timeoutTimer)
      this.timeoutTimer = setTimeout(() => {
        this.loadList(true);
        this.timer();
      }, FLUSH_TIME_SECONDS * 2000)
    },
    // 选择照片
    async handlerToolClick (type) {
      let files = {}
      if (type == 'img') {
        files = await selectFile(false, 'image/*, video/*');
      } else if (type == 'camera') {
        files = await selectFile(true, 'image/*, video/*');
      }
      files.forEach(item => {
        Exif.getData(item, function () {
          console.log('exif--------');
          console.log(Exif.getAllTags(this));
          // let Orientation = Exif.getTag(this, 'Orientation');
        });
      })
      if (files.length > 9) {
        this.$toast.fail({
          message: '发送图片不能超过九张！',
          position: 'top'
        })
        return false
      }
      const filesRes = await this.$upload.multipleFilesUpload(files)
      filesRes.forEach(item => {
        this.sendImage(item.filename)
      })
      this.isShowOperation = false;
    },
    // 切换消息类型
    handleMsgType (item) {
      if (item.msgType === 2) {
        return 'img'
      } else {
        return 'text'
      }
    },
    handleImgClick (data) {
      this.showImgPreview = true
      for (let i in this.imgList) {
        if (this.imgList[i] == data.image) {
          this.startPosition = parseInt(i)
        }
      }
    },
    imageLoad () {
      this.goToEnd()
    },
    handleMsgClick (data) {
      this.$router.push({
        name: 'information', query: {
          articleId: data.articleId, name: data.name,
          createTime: data.createTime
        }
      })
    },
    handleInput () {
      if (this.inputValue.length > 100) {
        this.inputValue = this.inputValue.substring(0, 100)
      }
    },
    handleFocus () {
      this.inputFocus = true
    },
    handleContentClick () {
      this.inputFocus = false
    }
  },

}
</script>

<style lang="scss" scoped>
.chatView {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;

    &_item {
      margin-top: 20px;
    }
  }

  .toolBarWrap {
    background-color: #fff;
    padding-bottom: 300px;
  }
}
</style>
