<template>
  <div
    class="messageItemView"
    :class="isMine ? 'isMineMessageItemView' : 'notMineMessageItemView'"
  >
    <!--    <div class="userIcon">-->
    <!--      1111 <img :src="userIcon" />-->
    <!--    </div>-->
    <AvatarFrame class="userIcon" :user-info="{
      avatar:item.userAvatar || '',
      avatarFrameUrl: item.userAvatarFrameUrl || ''
    }"></AvatarFrame>
    <div class="content">
      <p class="time">{{ getTime }}</p>
      <video
        :src="value"
        v-if="messageType === 'img' && isVideo(value)"
        controls
        :poster="value + '?x-oss-process=video/snapshot,t_50,f_jpg,ar_auto'"
      />
      <img
        class="imgMessage"
        :src="value"
        v-else-if="messageType === 'img'"
        @click="handleImgClick"
        @load="handleImageLoad"
      />
      <div
        :class="isMine?'flex-row-end': 'flex-row'"
        v-else-if="messageType === 'text'"
        ref="msg1"
      >
        <span class="textMessage" v-html="value" ref="msg"></span>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {isURL, isVideo} from "@/utils/utils";
import {USER_IMAGE, ADMIN_IMAGE} from "@/config/config";

export default {
  components: {
    AvatarFrame
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    isMine: Boolean,
    userIcon: String,
    value: String,
    messageType: {
      type: String,
      default: "text",
      validator: (value) => {
        return ["text", "img"].includes(value);
      },
    },
    articleId: String,
    name: String,
    time: String,
    superAdmin: Number,
  },
  computed: {
    resUserIcon () {
      if (this.superAdmin === 1 || this.superAdmin === 2) {
        return ADMIN_IMAGE;
      } else if (isURL(this.userIcon)) {
        return this.userIcon;
      } else {
        return USER_IMAGE;
      }
    },
    getTime () {
      const {time} = this;
      return time.substring(time.indexOf("-") + 1, time.lastIndexOf(":"));
    },
  },
  created () {
    console.log(this.item)
    this.$nextTick(function () {
      if (this.$refs.msg && this.$refs.msg.childNodes[1]) {
        this.$refs.msg.childNodes[1].onclick = () => {
          this.$emit("click");
        };
      }
    });
  },
  methods: {
    isVideo,
    handleImgClick () {
      this.$emit("imgClick");
    },
    handleImageLoad () {
      this.$emit("load");
    },
  },
};
</script>

<style lang="scss" scoped>
.messageItemView {
  display: flex;
  margin: 0 -10px;

  .userIcon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;

    .time {
      font-size: 12px;
      margin: 0;
      margin-bottom: 3px;
      color: $remarkColor;
    }

    video {
      width: 100%;
      height: 150px;
    }

    .imgMessage {
      width: 100%;
      border-radius: 4px;
    }

    .textMessage {
      padding: 10px;
      margin: 0;
      max-width: 260px;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;

      ::v-deep a {
        color: red !important;
      }
    }
  }
}

.isMineMessageItemView {
  justify-content: flex-end;

  .userIcon {
    order: 1;
  }

  .content {
    order: 0;
    margin-left: 60px;

    .time {
      display: flex;
      justify-content: flex-end;
    }
  }

  .textMessage {
    background-color: $mainColor;
    @include mainText;
    color: white;
  }
}

.notMineMessageItemView {
  .textMessage {
    background-color: white;
    @include mainText;
  }

  .content {
    margin-right: 60px;
  }
}
</style>
